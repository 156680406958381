<template>
	<div class="grid w-full min-h-screen overflow-hidden grid-cols-1 md:grid-cols-5">
		<div class="flex flex-col h-full col-span-1 md:col-span-2">
			<div class="flex-1 h-full pt-10 sm:pt-20 px-3 sm:px-0 ">
				<div class="max-w-md px-2 mx-auto">
					<img src="../../../assets/images/dscvry_logo-grey.svg" class="h-6"/>
					<Text content="We just sent you an email" customClass="mt-8 tracking-tight" size="3xl" color="gray-800" />
				</div>
				<div class="flex flex-col h-full mt-4">
					<div class="max-w-md px-2 mx-auto space-y-6 w-full">
						<Text content="We need to validate your email address before we get started. All you need to do is click the link in the email, and you'll come right back." color="gray-800" />
						<div class="max-w-md mx-auto my-6 flex flex-col">
							<Text content="Haven't gotten it yet?" color="gray-800" size="sm" />
							<Text @click="$emit('onSubmit')" content="Resend the email" color="primary-600" customClass="cursor-pointer self-start flex underline text-sm mt-1" />
						</div>
					</div>
				</div>
			</div>
<!--			<div class="flex-none">-->
<!--				<div>-->
<!--					<div class="max-w-md mx-auto my-6 px-5 sm:px-2 flex flex-col">-->
<!--						<Text content="Back to Log In" color="gray-800" size="sm" />-->
<!--						<Text @click="$emit('goTo', '/login')" content="Log In" color="primary-600" customClass="cursor-pointer self-start flex underline text-sm mt-1" />-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
		</div>
		<div class="hidden h-full col-span-1 md:col-span-3 md:block">
			<Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover" />
		</div>
	</div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Image from '../../atoms/Image/Image.vue';
import Input from '../../molecules/Inputs/Component/Component.vue';
import Button from '../../atoms/Button/Button.vue';
import Icon from '../../atoms/Icons/Icons.vue';

export default {
	emits: [
		'onChangeAgree'
	],
	components: {
		Text,
		Link,
		Checkbox,
		Image,
		Input,
		Button,
		Icon
	},
	props: {
		image: {
			type: Object,
			default: () => {}
		},
		logo: {
			type: Object,
			default: () => {}
		},
		inputs: {
			type: Object,
			default: () => {}
		},
		button: {
			type: Object,
			default: () => {}
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		isChecked: function (val) {
			this.$emit('onChangeAgree', val)
		}
	},
	data() {
		return {
			isChecked: false,
		}
	}
};
</script>
